'use strict';

var scrollLockToggle = function () {
    var isPopupOpen = $('.modesh-footer-container').find('.active').length > 0;
    if (isPopupOpen) {
        $('body').addClass('scroll-lock');
    } else {
        $('body').removeClass('scroll-lock');
    }
};

$('body').on('click', '.change-currency', function () {
    console.log('sdfdsfdsf');
    $('.currency-footer').toggleClass('active');
    $('.language-footer').removeClass('active');
    scrollLockToggle();
});

$('body').on('click', '.change-lang', function () {
    $('.language-footer').toggleClass('active');
    $('.currency-footer').removeClass('active');
    scrollLockToggle();
});

$('body').on('mouseup', function (e) {
    var container = $('.language-footer.active , .currency-footer.active');
    if (container.length > 0) {
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $(container).removeClass('active');
            $('body').removeClass('scroll-lock');
        }
    }
});
