/* eslint-disable new-cap */
'use strict';

var toastr = require('toastr');

// eslint-disable-next-line no-unused-vars, require-jsdoc
function searchProfileQuery(inp) {
    var $val = inp.toLowerCase();
    // var $newVal = $val.replace(/-/g, '');
    var niceIdea = [];
    $('#profile-search-results').empty();
    // $('#profile-search-results').empty().append
    $('.menu-group .account-box ul li a[data-search]').each(function () {
        var $data = $(this).data('search').toLowerCase();
        if ($data.includes($val)) {
            niceIdea.push('<div class="account-box smaller"><ul><li>' + $(this).get(0).outerHTML + '</li></ul></div>');
        }
    });

    for (var i = 0; i < niceIdea.length; ++i) {
        // do something with `substr[i]`
        var $this = niceIdea[i];
        $('#profile-search-results').append($this);
    }
}

module.exports = {
    searchProfile: function () {
        $(document).on('keyup', '.js-profile-search input', function () {
            var $this = $(this);
            var $val = $this.val();
            var $parent = $this.parent();

            if ($val.length > 0 && $val) {
                $parent.find('.close-profile-search').addClass('show');
                searchProfileQuery($val);
                $('.js-profile-search-hide').hide();
            } else {
                $parent.find('.close-profile-search').removeClass('show');
                $('.js-profile-search-hide').show();
                $('#profile-search-results').empty();
            }
        });
    },
    clearSearchProfile: function () {
        $(document).on('click', '.js-clear-profile-search', function () {
            var $this = $(this);
            $('.js-profile-search input').val('');
            $this.removeClass('show');
            $('.js-profile-search-hide').show();
            $('#profile-search-results').empty();
        });
    },

    uploadProfileImage: function () {
        $('.profile-img-container').on('click', function (e) {
            e.preventDefault();
            $('#uploadProfilePic').trigger('click');
        });
        $('#uploadProfilePic').on('change', function (event) {
            var selectedFile = event.target.files[0];
            var url = $(this).data('url');
            if (selectedFile) {
                var formData = new FormData();
                formData.append('profilePic', selectedFile);
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function (response) {
                        if (response.success && response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                        } else {
                            $.toastr().error(response.message);
                            $.spinner().stop();
                        }
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        }
                        $.spinner().stop();
                    }
                });
            }
        });
    }
};
