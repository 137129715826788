'use strict';

module.exports = function () {
    $(document).ready(function () {
        var isLoggedIn = $('#loggedIn').val();
        // if (isLoggedIn === 'true' && !hideInfoWidget && walletValue > 0) {
        //     $('.page').addClass('loggedIn');
        //     $('.wallet-info-widget').addClass('show');
        //     $('.main-page-content').addClass('show-wallet-info');
        //     $('.main-page-content').scrollTop(0);
        // }
        if (isLoggedIn === 'true') {
            $('.page').addClass('loggedIn');
        } else {
            $('.page').removeClass('loggedIn');
        }
    });

    $('.js-hide-info-widget').on('click', function () {
        localStorage.setItem('info-widget', true);
        $('.wallet-info-widget').removeClass('show');
        $('.main-page-content').removeClass('show-wallet-info');
        $('.main-page-content').scrollTop(0);
    });

    $('.logout').on('click', function () {
        localStorage.removeItem('info-widget');
    });
};
