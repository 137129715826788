'use strict';
var toastr = require('toastr');
/**
 * Show Toast Error Message.
 * @param {string} message message to show.
 */
function errorToastr(message) {
    toastr.remove();
    toastr.options.closeButton = true;
    toastr.options.closeHtml =
        '<button><i class="uil uil-multiply"></i></button>';
    toastr.error(message);
}
/**
 * Show Toast Success Message.
 * @param {string} message message to show.
 */
function successToastr(message) {
    toastr.remove();
    toastr.success(message);
}
/**
 * Show Clipboard Copy Message.
 * @param {string} message message to show.
 */
function clipboardToastr(message) {
    $('.copy-to-clipboard').html(message).addClass('show');

    setTimeout(function () {
        $('.copy-to-clipboard').removeClass('show');
    }, 3000);
}
$.toastr = function () {
    var Fn = function () {
        this.error = function (message) {
            errorToastr(message);
        };
        this.success = function (message) {
            successToastr(message);
        };
        this.showClipboard = function (message) {
            clipboardToastr(message);
        };
    };
    return new Fn();
};
