'use_strict';
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var CUSTOM_MOENGAGE = require('../moengage');

$(document).ready(function () {
    processInclude(require('./profileSearch'));
});

module.exports = function () {
    // handling menu click function from the home page
    $('body').on('click', '.js-language-selector', function (e) {
        e.preventDefault();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('#header-side').toggleClass('openMenu');
        $('body').toggleClass('scroll-lock');
        $(document).trigger('GTM:sideMenuOpen');
    });
    
    $('.navbar-toggler-desktop').click(function (e) {
        e.preventDefault();
        $('#header-side').toggleClass('openMenu');
        $('.main-menu').toggleClass('in');
        $('body').toggleClass('scroll-lock');
        $('.page').toggleClass('showMenu');
        $(document).trigger('GTM:sideMenuOpen');
    });

    $('.js-close-navigation').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
        $('#header-side').toggleClass('openMenu');
        $('body').removeClass('scroll-lock');
        $('.page').removeClass('showMenu');
        $(document).trigger('GTM:sideMenuClose');
    });

    $('.blockerCanvas').click(function (e) {
        e.preventDefault();
        $('.main-menu').removeClass('in');
        $('#header-side').removeClass('openMenu');
        $('body').removeClass('scroll-lock');
        $('.page').removeClass('showMenu');
        $(document).trigger('GTM:sideMenuClose');
    });
    $('.nav-link.logout').on('click', function (e) {
        e.preventDefault();
        $(document).trigger('logout:success');
        if (typeof Moengage !== 'undefined' && Moengage) {
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.logout, null, $(this).attr('href'));
        } else {
            window.location.href = $(this).attr('href');
        }
    });
};
