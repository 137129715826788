'use strict';
var cookies = require('./cookies');

const MAX_COOKIE_LIFE_IN_DAYS = 90; // Expires in 90 days
const MAX_SEARCHES = 5; // Adjust this as needed

/**
 * Get a list of recent searches
 * @returns array of recent search results
 */
function getRecentSearches() {
    const recentSearches = cookies.getCookie("recentSearches");
    if (recentSearches && recentSearches.length > 0) {
        return JSON.parse(recentSearches);
    } else {
        return [];
    }
}

function setRecentSearchCookie(recentSearches) {
    cookies.setCookie("recentSearches", JSON.stringify(recentSearches), MAX_COOKIE_LIFE_IN_DAYS);
}

// Function to store recent searches in a cookie
function storeRecentSearch(query) {
    let recentSearches = getRecentSearches();
    
    // insert only if it doesn't already exist
    for (let i = 0; i < recentSearches.length; i++) {
        if (recentSearches[i] === query) {
            return;
        }
    }

    // Add the new query to the beginning of the array
    recentSearches.unshift(query);

    // Ensure we only keep the latest MAX_SEARCHES searches
    if (recentSearches.length > MAX_SEARCHES) {
        recentSearches.pop();
    }

    // Store the updated recent searches in a cookie
    setRecentSearchCookie(recentSearches)
}

function clearAllRecentSearch() {
    document.cookie = "recentSearches=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function deleteRecentSearchItem(query) {
    let recentSearches = getRecentSearches();
    for (let i = 0; i < recentSearches.length; i++) {
        if (recentSearches[i] === query) {
            recentSearches.splice(i, 1);
            break;
        }
    }
    // Store the updated recent searches in a cookie
    setRecentSearchCookie(recentSearches)
}

function getRecentSearchItemHtml() {
    let recentSearches = getRecentSearches();
    var html = '';
    for (let i = 0; i < recentSearches.length; i++) {
        html += '<li class="search-term"><div class="recent-items"><i class="uil uil-clock"></i><p class="search-item">' + recentSearches[i] + '</p><button type="button" class="js-deleteSearchItem"><i class="uil uil-trash-alt"></i></button></div></li>';
    }
    return html;
}

function getRecentSearchesCount() {
    return getRecentSearches().length;
}

function getRecentSearchItem(index) {
    const recentSearches = getRecentSearches();
    if (recentSearches.length > 0) {
        return recentSearches[index];
    } else {
        return null;
    }
}

function getRecentSearchItemIndex(query) {
    const recentSearches = getRecentSearches();
    if (recentSearches.length > 0) {
        return recentSearches.indexOf(query);
    } else {
        return -1;
    }
}

module.exports = {
    getRecentSearches: getRecentSearches,
    setRecentSearchCookie: setRecentSearchCookie,
    storeRecentSearch: storeRecentSearch,
    clearAllRecentSearch: clearAllRecentSearch,
    deleteRecentSearchItem: deleteRecentSearchItem,
    getRecentSearchItemHtml: getRecentSearchItemHtml,
    getRecentSearchesCount: getRecentSearchesCount,
    getRecentSearchItem: getRecentSearchItem,
    getRecentSearchItemIndex: getRecentSearchItemIndex
};
