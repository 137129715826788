'use_strict';

module.exports = function () {
    $('.js-currency-select, .js-language-select').on('change', function () {
        var $cur = $(this);
        var selectedVal = $cur.val();
        $cur.attr('data-selected', selectedVal);
        var $previousVal = $cur.attr('data-prev-selected');

        if (selectedVal !== $previousVal) {
            var action = $('.page').data('action');
            var localeCode = $('.js-language-select [type=radio]:checked').val();
            var localeCurrencyCode = $('.js-currency-select [type=radio]:checked').val();
            var data = { localeCode: localeCode, localeCurrencyCode: localeCurrencyCode };
            if ($(this).hasClass('js-currency-select')) {
                $(document).trigger('currency:success', data);
            } else {
                $(document).trigger('country:success', data);
            }


            var queryString = $('.page').data('querystring');
            var url = $cur.closest('div').data('url');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString: queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: action
                },
                success: function (response) {
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
};
